<template>
  <!-- Component Start -->
  <div class="tooltip" :class="displayTooltip ? 'display-tooltip' : ''" @click="onClicked()">
    <slot></slot>
    <span class="tooltiptext">{{ title }}</span>
  </div>
  <!-- Component End  -->
</template>

<script setup>
const { title } = defineProps({
  title: {
    type: String,
    required: true,
  },
});

let displayTooltip = ref(false);

function onClicked() {
  if (!displayTooltip.value) {
    displayTooltip.value = true;
  }

  setTimeout(() => {
    displayTooltip.value = false
  }, 1000);
}
</script>

<style>
.tooltip {
  @apply relative flex cursor-pointer;
}
.tooltip .tooltiptext {
  @apply invisible text-xs bg-white text-black text-center absolute z-[1] p-[2px] rounded-md right-0 bottom-[-24px] after:content-[""] after:absolute after:ml-[-5px] after:border-[5px] after:border-solid after:border-[transparent_transparent_white_transparent] after:bottom-full whitespace-nowrap;
}
.tooltiptext::after {
  @apply content-[""] absolute ml-[-5px] border-[5px] border-solid border-[transparent_transparent_white_transparent] left-auto right-[5px] bottom-full;
}
.tooltip:hover .tooltiptext, .display-tooltip .tooltiptext {
  @apply visible;
}
</style>
