import { defineStore } from "pinia";

// main is the name of the store. It is unique across your application
// and will appear in devtools

export const useProductStore = defineStore("filter", {
  state: () => {
    return {
      sortOptions: [
        {
          value: 0,
          text: "sort.priceLow2High",
        },
        {
          value: 1,
          text: "sort.priceHigh2Low",
        },
        {
          value: 2,
          text: "sort.orderRecommended",
        },
        {
          value: 3,
          text: "sort.nameAsc",
        },
        {
          value: 4,
          text: "sort.nameDesc",
        },
      ],
      selectedSortOption: 2,
      checked: [],
      search: "",
      category: [],
      subcategory: [],
      service: [],
      infection: [],
      collection: [],
      test_type: [],
      filterOptionsData: [],
      min: 0,
      max: 0,
      filtredTests: [],
      filterOptions: {},
      selectedTest: {},
      infectionSortType: "AND",
      price: [],
      
      filterToClear: [
        "category",
        "service",
        "infection",
        "collection",
        "test_type",
        "checked",
        "price",
        "subcategory"
      ]
    };
  },
  getters: {
    getSortOptions(state) {
      return state.sortOptions.map((data) => ({
        ...data,
        text: useI18n().t(data.text),
      }));
    },
    getCheckedFilter(state) {
      let selectedFilterFormat = [...state.test_type, ...state.collection, 
        ...state.infection, ...state.service,...state.category, ...state.subcategory, 
      ]
      return [...selectedFilterFormat, ...state.price]
    },

  },
  actions: {
    async getFilterOptions(minData, maxData) {
      this.filterOptionsData.min_price = minData
      this.filterOptionsData.max_price = maxData
      this.min = minData;
      this.max = maxData;
    },
    setFiltredTests(value) {
      this.filtredTests = value;
    },
    setProductSearch(value) {
      this.search = value;
    },
    setMinMaxPrice(minValue, maxValue) {
      const { $formatCurrency } = useNuxtApp();
      this.min = minValue;
      this.max = maxValue;
      let item = $formatCurrency(this.min) + " - " + $formatCurrency(this.max)
      this.price = [{ 'title': item }];
    },

    setSearchValue(value) {
      this.search = value;
    },
    setChecked(item) {
      this.checked = !this.checked.includes(item)
        ? [...this.checked, item]
        : this.checked.filter((i) => i !== item);

    },
    addChecked(item) {
      this.checked = [...this.checked.filter((x) => x !== item), item];
    },
    setCheckedAll(item) {

      let slugArray = item.map(data => data.slug)
      let allItemsPresent = item.every(data => this.subcategory.includes(data));

      if (allItemsPresent) {
        this.subcategory = this.subcategory.filter(data => !item.includes(data))
        this.checked = this.checked.filter(data => !slugArray.includes(data));
      } else {
        item.forEach(data => {
          if (!this.subcategory.includes(data)) {
            this.checked.push(data.slug);
            this.subcategory.push(data);
          }
        });
      }
    },

    setInfectionSortType(value) {
      this.infectionSortType = value;
    },
    removeChecked(item) {
      this.checked = this.checked.filter((i) => i !== item);
    },

    handleSubcategorySelection (item, parentCategory) {
      if (this.category.includes(parentCategory)){
        this.checked = this.checked.filter((i) => i !== parentCategory.slug);
        this.setCollection(parentCategory, "category");
      }
    // Update the collection with the selected subcategory
    this.setCollection(item, "subcategory");

    // Check if all subcategories are selected
    const allSubcategoriesSelected = parentCategory && parentCategory.subcategories
      .every(subcategory => this.subcategory.includes(subcategory));
    // If all subcategories are selected, update the collection and set as checked
      if (allSubcategoriesSelected) {
        this.setCollection(parentCategory, "category");
        this.setChecked(parentCategory.slug);
      }
    },
 
    handleCategory(item) {
      // Update the collection with the selected category
      this.setCollection(item, "category");
      // If the category has subcategories, set them all as checked
      if (item.subcategories) {
        this.setCheckedAll(item.subcategories);
      }
    },
   
    clearSingleItem(item) {
     
      if (this.category.includes(item)){
        this.handleCategory(item)
        
      } if (this.subcategory.includes(item)){
        let category = this.category.find((data) => data.subcategories && data.subcategories
        .find((data1) => data1 && (data1 == item)));
        this.setChecked(item.slug);
        this.handleSubcategorySelection(item, category)
      } if (this.price.includes(item)) {
        this.price = this.price.filter((i) => i !== item);
        this.min = this.filterOptionsData.min_price
        this.max = this.filterOptionsData.max_price;
      }

      this.filterToClear.map((data) => {
        if (this[data].includes(item)) {
          this[data] = this[data].filter((i) => i !== item)
        }
      })
      this.checked = this.checked.filter((i) => i !== item.slug);
    },
    clearAllFilters(data) {
      const filtersSelected = data ?? this.filterToClear
      filtersSelected.forEach((filter) => {
        if (filter == 'price') {
          this.price = [];
          this.min = this.filterOptionsData.min_price
          this.max = this.filterOptionsData.max_price;
        }
        this[filter] = [];
      });
    },
    setSortOption(item) {
      this.selectedSortOption = item;
    },
    setSortOptionMobile(item) {
      this.selectedSortOption = item.value;
    },
    setCategory(item) {
      this.category = [...this.category.filter((x) => x.slug !== item.slug), item];
    },
    removeCategory(item) {
      this.category = this.category.filter((category) => category.slug !== item.slug);
    },
    setService(item) {
      let isExisted = this.service.includes(item);
      if (!isExisted) this.service = [...this.service, item];
      else this.service = this.service.filter((i) => i !== item);
    },
    setCollection(item, type) {
      let isExisted = this[type].includes(item);
      this[type] = isExisted ? this[type].filter((i) => i !== item) : [...this[type], item];
    },

    setInfection(item) {
      let isExisted = this.infection.includes(item);
      this.infection = (!isExisted) ? [...this.infection, item] : this.infection.filter((i) => i !== item)
    },
    setFilterOptions(value) {
      this.filterOptions = value;
    },
    setSelectedTest(value) {
      this.selectedTest = value;
    },
    appendFilterOptions(min, max) {
      this.filterOptions.min_price = Number(min);
      this.filterOptions.max_price = Number(max);
    }
  },
});
